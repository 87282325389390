import { Component, Input } from '@angular/core';
import { DocumentComplianceData } from '../../model/document-compliance/document-compliance-data';

@Component({
  selector: 'app-phx-document-file-compliance',
  templateUrl: './phx-document-file-compliance.component.html',
  styleUrls: ['./phx-document-file-compliance.component.less']
})
export class PhxDocumentFileComplianceComponent {
  @Input() complianceData: DocumentComplianceData;

  warningStatusList = ['Warning', 'Info'] as const;
  errorStatusList = ['Error', 'Critical', 'Fatal'] as const;
}
