import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, TemplateRef } from '@angular/core';
import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { first, map } from 'rxjs/operators';
import { lastValueFrom, Observable, Subject } from 'rxjs';

import { DialogComment, DialogOptions, DialogResultType, EntityList } from '../model';
import { PhxLocalizationService } from './phx-localization.service';
import { PhxDialogConfirmTemplateComponent } from '../components/phx-dialog-confirm-template/phx-dialog-confirm-template.component';
import { PhxDialogErrorTemplateComponent } from '../components/phx-dialog-error-template/phx-dialog-error-template.component';
import { PhxDialogNotifyTemplateComponent } from '../components/phx-dialog-notify-template/phx-dialog-notify-template.component';
import { PhxDialogCommentTemplateComponent } from '../components/phx-dialog-comment-template/phx-dialog-comment-template.component';
import { PhoenixCommonModuleResourceKeys } from '../PhoenixCommonModule.resource-keys';
import { DialogState, DialogStateWithReasons } from '../model/dialog-state';
import { ApiService } from './api.service';
import { DeclinedReason } from '../model/declined-reason';
import { NotifyComponentProperties } from '../components/phx-dialog-notify-template/models/notify-component-properties.model';
import { ConfirmComponentProperties } from '../components/phx-dialog-confirm-template/models/confirm-component-properties.model';
import { DialogComponent } from './dialog/models/dialog-component.model';

@Injectable()
export class DialogService {
  private dialogClosed$ = new Subject<void>();
  private dialogOpened$ = new Subject<void>();
  private dialogsCount = 0;
  constructor(@Inject(DOCUMENT) private document: Document, private locale: PhxLocalizationService, private apiService: ApiService, private dialog: Dialog) {}

  get dialogHasClosed$(): Observable<void> {
    return this.dialogClosed$.asObservable();
  }

  get dialogHasOpened$(): Observable<void> {
    return this.dialogOpened$.asObservable();
  }

  /**
   * Opens a dialog component or template in a popup.
   *
   * @template INJECTED_DIALOG_DATA - Type of data to be injected into the dialog.
   * @template DIALOG_RETURN_TYPE - Type of data returned by the dialog.
   * @param component - The component, implementing DialogComponent interface or a template to be displayed in the dialog.
   * @param dialogConfig - Optional configuration for the dialog.
   * @returns - Reference to the dialog, which can be used to interact with or close the dialog.
   *
   * @example
   * // Example 1: Opening a Component, which implements DialogComponent interface in a Popup
   * export class FooComponent implements DialogComponent<{ foo: string }, boolean> {
   *   constructor(public dialogRef: DialogRef<boolean>, @Inject(DIALOG_DATA) public dialogData: { foo: string }) { }
   *
   *   public confirm(): void {
   *     this.dialogRef.close(true);
   *   }
   *
   *   public reject(): void {
   *     this.dialogRef.close(false);
   *   }
   * }
   *
   * const dialogRef = dialogService.showInPopup(FooComponent, {
   *    data: { foo: 'foo' }
   *  });
   *
   * @example
   * // Example 2: Opening a Template in a Popup
   * import { TemplateRef, ViewChild } from '@angular/core';
   *
   * public @ViewChild('myDialogTemplate') myDialogTemplate: TemplateRef<void>;
   *
   * constructor(private dialogService: DialogService) {}
   *
   * public openDialog(): void {
   *  const dialogRef = dialogService.showInPopup<void, void>(myDialogTemplate, {
   *     hasBackdrop: true,
   *     backdropClass: 'custom-backdrop-class',
   *     panelClass: 'custom-panel-class'
   *  });
   * }
   *
   */
  showInPopup<INJECTED_DIALOG_DATA, DIALOG_RETURN_TYPE>(
    component: ComponentType<DialogComponent<INJECTED_DIALOG_DATA, DIALOG_RETURN_TYPE>> | TemplateRef<any>,
    dialogConfig?: DialogConfig<INJECTED_DIALOG_DATA, DialogRef<DIALOG_RETURN_TYPE, DialogComponent<INJECTED_DIALOG_DATA, DIALOG_RETURN_TYPE>>> & { excludeModalClass?: boolean }
  ): DialogRef<DIALOG_RETURN_TYPE> {
    const panelClass = dialogConfig?.excludeModalClass ? [] : ['modal-dialog'];
    const dialog = this.dialog.open(component, {
      ...(dialogConfig ?? {}),
      backdropClass: ['modal-backdrop', 'in'].concat(dialogConfig?.backdropClass ?? ''),
      hasBackdrop: dialogConfig?.hasBackdrop ?? true,
      panelClass: dialogConfig?.panelClass && panelClass.concat(Array.isArray(dialogConfig.panelClass) ? dialogConfig.panelClass : dialogConfig.panelClass?.split(' ')),
      disableClose: dialogConfig?.disableClose ?? true
    });

    dialog.closed.pipe(first()).subscribe(() => {
      if (!--this.dialogsCount) {
        this.document.body.style.removeProperty('overflow');
      }
      this.dialogClosed$.next();
    });

    this.dialogsCount++;
    this.document.body.style.overflow = 'hidden';
    this.dialogOpened$.next();

    return dialog;
  }

  error(title?: string, message?: string, dialogOptions?: DialogOptions): void {
    const modalOption = this.getModalOption(dialogOptions);
    this.showInPopup(PhxDialogErrorTemplateComponent, {
      data: {
        title: title || this.locale.translate('common.generic.error'),
        message: message || 'An unknown error has occurred.'
      },
      panelClass: modalOption.class,
      disableClose: modalOption.disableClose
    });
  }

  notify(title?: string, message?: string, dialogOptions?: DialogOptions): Promise<void> {
    return new Promise<void>(resolve => {
      const modalOption = this.getModalOption(dialogOptions);
      const data: NotifyComponentProperties = {
        title: title || 'Notification',
        message: message || 'Unknown application notification.',
        callbackFn: resolve
      };
      this.showInPopup(PhxDialogNotifyTemplateComponent, {
        data,
        panelClass: modalOption.class,
        disableClose: modalOption.disableClose
      });
    });
  }

  confirm(title?: string, message?: string, dialogOptions?: DialogOptions, yesButtonText?: string, noButtonText?: string): Promise<DialogResultType> {
    return new Promise((resolve, reject) => {
      const modalOption = this.getModalOption(dialogOptions);
      const data: ConfirmComponentProperties = {
        title: title || 'Confirmation',
        message: message || 'Confirmation required.',
        yesButtonText,
        noButtonText,
        yesCallbackFn: () => {
          resolve(DialogResultType.Yes);
        },
        noCallbackFn: () => {
          reject(DialogResultType.No);
        }
      };
      this.showInPopup(PhxDialogConfirmTemplateComponent, {
        data,
        panelClass: modalOption.class,
        disableClose: modalOption.disableClose
      });
    });
  }

  confirmDelete(message: string = this.locale.translate('common.dialog.confirmDeleteMessage')) {
    return this.confirm(this.locale.translate('common.dialog.confirmDeleteTitle'), message);
  }
  comment(dialogState: DialogState = {}, dialogOptions?: DialogOptions): Promise<string> {
    return new Promise((resolve, reject) => {
      const modalOption = this.getModalOption(dialogOptions);
      const state: DialogState = {
        ...dialogState,
        title: dialogState.title || this.locale.translate(PhoenixCommonModuleResourceKeys.phxDialogComment.title),
        helpBlock: dialogState.helpBlock || this.locale.translate(PhoenixCommonModuleResourceKeys.phxDialogComment.helpblock),
        saveButtonText: dialogState.saveButtonText || this.locale.translate(PhoenixCommonModuleResourceKeys.phxDialogComment.saveBtn),
        saveCallbackFn: (comment: string) => resolve(comment),
        cancelCallbackFn: reject
      };

      this.showInPopup(PhxDialogCommentTemplateComponent, {
        data: state,
        panelClass: modalOption.class,
        disableClose: modalOption.disableClose
      });
    });
  }

  commentWithReasons(dialogState: DialogStateWithReasons = {}, dialogOptions?: DialogOptions): Promise<DialogComment> {
    return new Promise(async (resolve, reject) => {
      const modalOption = this.getModalOption(dialogOptions);

      const declineReasonList = await this.getDeclineReasonList(dialogState.entityTypeId);

      const state: DialogStateWithReasons = {
        ...dialogState,
        title: dialogState.title || this.locale.translate(PhoenixCommonModuleResourceKeys.phxDialogComment.title),
        helpBlock: dialogState.helpBlock || this.locale.translate(PhoenixCommonModuleResourceKeys.phxDialogComment.helpblock),
        saveButtonText: dialogState.saveButtonText || this.locale.translate(PhoenixCommonModuleResourceKeys.phxDialogComment.saveBtn),
        declineReasonList,
        showSelectionControls: dialogState.showSelectionControls,
        cancelCallbackFn: reject,
        saveReasonIDsCallbackFn: async (dialogComment: DialogComment) => resolve(dialogComment)
      };

      this.showInPopup(PhxDialogCommentTemplateComponent, {
        data: state,
        panelClass: modalOption.class,
        disableClose: modalOption.disableClose
      });
    });
  }

  async getDeclineReasonList(id: number) {
    try {
      const filter = oreq.filter('EntityTypeId').eq(id);
      const params = oreq.request().withFilter(filter).url();
      return lastValueFrom(this.apiService
        .query<EntityList<DeclinedReason>>('declinedReason' + '?' + params)
        .pipe(map(x => x.Items)));
    } catch (e) {
      return null;
    }
  }

  private getModalOption(dialogOption: DialogOptions = {}): { class: string; disableClose: boolean } {
    return {
      class: `modal-${dialogOption.size || 'sm'}` + (dialogOption.classList || []).join(' '),
      disableClose: Boolean(dialogOption.backdrop)
    };
  }
}
