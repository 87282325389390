import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { DxComponent, DxDataGridComponent, DxTemplateHost, WatcherHelper } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { defer, get } from 'lodash';
import { ApiService, DialogService, PhxConstants, PhxLocalizationService } from '../..';
import {
  DialogResultType,
  PhxDataTableColumn,
  PhxDataTableConfiguration,
  PhxDataTableEditingConfig,
  PhxDataTableSelectionMode,
  PhxDataTableState,
  PhxDataTableStateDetail,
  PhxDataTableStateSavingMode,
  PhxDataTableSummaryItem,
  PhxDataTableUserProfile
} from '../../model';
import { PhxDataTableService } from '../../services/phx-data-table.service';
import ODataStore from 'devextreme/data/odata/store';
import { environment } from '../../../../environments/environment';
import { CookieService } from '../../services/cookie.service';
import { AuthService } from '../../services/auth.service';
import DevExpress from 'devextreme/bundles/dx.all';
import { from, Observable, Subject } from 'rxjs';
import { finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ReportSearchParams } from '../../../report-azsearch/model/report-search-params';
import { FormBuilder, FormGroup } from '../../ngx-strongly-typed-forms';
import { IReportShareDetails } from '../../model/data-table/i-report-share-details';
import { ReportShareDialogComponent } from '../report-share-dialog/report-share-dialog.component';
import { PhoenixCommonModuleResourceKeys } from '../../PhoenixCommonModule.resource-keys';
import { ReportViewStateService } from '../../services/report-view-state.service';
import { ILoadedReportViewData, IReportViewState } from '../../model/ireport-view-state';
import { ReportAZSearchService } from '../../../report-azsearch/service/report-azsearch.service';
import { ToastService } from '../../services/toast.service';
import LoadOptions = DevExpress.data.LoadOptions;
import { PhxAzsearchColumnChooserComponent } from '../phx-azsearch-column-chooser/phx-azsearch-column-chooser.component';
import { RowKey } from '../../model/to-do-item';

@Component({
  selector: 'app-phx-azsearch-data-table',
  templateUrl: './phx-azsearch-data-table.component.html',
  styleUrls: ['./phx-azsearch-data-table.component.less']
})
export class PhxAZSearchDataTableComponent extends DxComponent implements OnInit, AfterContentInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('grid', { static: true }) grid: DxDataGridComponent;
  @ViewChild('addStateForm', { static: true }) addStateForm: any;
  @ViewChild('saveModal', { static: true }) saveModal: any;
  @ViewChild('reportShareDialog', { static: true }) reportShareDialog: ReportShareDialogComponent;
  @ViewChild(PhxAzsearchColumnChooserComponent) columnChooser: PhxAzsearchColumnChooserComponent;

  @Input() configuration: PhxDataTableConfiguration = new PhxDataTableConfiguration({});
  @Input() columns: Array<PhxDataTableColumn>;
  @Input() summary: Array<PhxDataTableSummaryItem>;
  dataSource: any = {};
  dataSourceUrl: string;
  @Input() dataSourceParams: any;
  selectColumns: string[];
  @Input() defaultSort: Array<{ selector: string, desc: boolean; }>;
  reportId: number;
  @Input() reportOdataKey: string;
  @Input() teamIds: string;
  @Input() contextualUserIds: string;
  @Input() componentName: string;
  @Input() exportFileName: string;
  @Input() debug = false;
  @Input() height = 'auto';
  @Input() dataStoreKey: string | string[];
  @Input() defaultStateName = '';
  @Input() editing: PhxDataTableEditingConfig;
  @Input() refreshGrid: Observable<{
    reportId: number,
    selectColumns: Array<string>;
  }>;
  @Input() showShareBtn = true;
  @Input() selectedRowKeys: number[] = [];

  @Input() selectedReportId: number = null;
  @Input() currentReportTypeId: number;
  /** NOTE: flag for showing todo list specific selection and selection styles */
  @Input() todoSelectionIsEnabled: boolean = false;


  @Output() selectedReportIdLoad: EventEmitter<number> = new EventEmitter<number>();
  @Output() teamIdsAndContextualUserIdsLoaded: EventEmitter<ILoadedReportViewData> = new EventEmitter<ILoadedReportViewData>();

  @Output() responseReceived: EventEmitter<any> = new EventEmitter<any>();
  @Output() editorPreparing: EventEmitter<any> = new EventEmitter<any>();
  @Output() contentReady: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() cellClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowPrepared: EventEmitter<any> = new EventEmitter<any>();
  @Output() cellPrepared: EventEmitter<any> = new EventEmitter<any>();
  @Output() masterRowExpanding: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadingStarted: EventEmitter<any> = new EventEmitter<any>();
  @Output() reportViewRemoved: EventEmitter<any> = new EventEmitter<any>();
  @Output() reportViewAdded: EventEmitter<any> = new EventEmitter<any>();
  @Output() contextMenuPreparing: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchParamsChanged: EventEmitter<ReportSearchParams> = new EventEmitter<ReportSearchParams>();
  @Output() resetSelectedReport: EventEmitter<void> = new EventEmitter<void>();
  @Output() isLoadingData: EventEmitter<void> = new EventEmitter<void>();

  initState: PhxDataTableState;
  states: Array<PhxDataTableUserProfile>;
  lastReportViewStateString: string;
  isSavingCurrentReportView = false;
  pendingSaveCurrentReportView = false;
  PhxDataTableStateSavingMode = PhxDataTableStateSavingMode;
  dateColumnFormat = PhxConstants.DateFormat.mediumDate;
  phxEditing: any = {};
  selectedState: PhxDataTableStateDetail = { Name: '', Description: '', Id: 0, OwnerProfileId: 0 };
  reportShareDetails: IReportShareDetails;
  nameIsChanged = false;
  newStateDescription = '';
  stateDetails: PhxDataTableStateDetail[] = [];
  totalCount: number;
  currentCount: number;
  phxDataSource: any = {};
  hasFilter = false;
  exportConfig = { enabled: this.configuration.enableExport, fileName: null };
  saveStateModalButtons = [];
  stateStoringConfig = {
    enabled: true,
    type: 'custom',
    storageKey: null,
    customLoad: () => {
    },
    customSave: (gridState) => {
      /** NOTE: if todo selection is enabled then the todo list handles the selected rows and not the grid state */
      if (this.todoSelectionIsEnabled) {
        gridState.selectedRowKeys = [];
      }
      // Grab reportViewState and componentName to prevent data corruption mid backend call.
      const reportViewState = this.buildReportViewState(gridState);
      const componentName = this.componentName;
      this.saveReportViewForComponent(reportViewState, componentName);
    },
    savingTimeout: 500
  };
  masterDetailConfig = {
    enabled: this.configuration.enableMasterDetail,
    template: this.configuration.masterDetailTemplateName
  };
  loadPanelDefaultConfig = {
    enabled: true,
    height: 90,
    indicatorSrc: './../../../../assets/loading.gif',
    showIndicator: true,
    showPane: true,
    text: this.configuration.loadPanelText,
    width: 200
  };
  loadPanelRebuildReportConfig = {
    enabled: true,
    height: 90,
    indicatorSrc: './../../../../assets/loadingSpinnerReport.gif',
    showIndicator: true,
    showPane: true,
    text: this.locale.translate('common.phxDataTableConfiguration.loadPanelRebuildReportText'),
    width: 200
  };
  loadPanelConfig = this.loadPanelDefaultConfig;
  currentUserProfileId: number;
  rootFormGroup: FormGroup<IReportShareDetails>;
  commonModuleResourceKeys: typeof PhoenixCommonModuleResourceKeys;
  isColumnChooserVisible = false;

  /** NOTE: a setTimeout is needed to handle a datgrid bug - see below for details */
  private dataGridTimeoutId: ReturnType<typeof setTimeout>;

  private combinedFilter: any;
  private initHookFired = false;
  private isDestroyed$ = new Subject<boolean>();
  private fireSelectionChanged = true;

  constructor(
    private eRef: ElementRef,
    ngZone: NgZone,
    private templateHost: DxTemplateHost,
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _watcherHelper: WatcherHelper,
    private dataTableService: PhxDataTableService,
    private reportViewStateService: ReportViewStateService,
    private apiService: ApiService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private locale: PhxLocalizationService,
    transferState: TransferState,
    private cookieSvc: CookieService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private reportService: ReportAZSearchService,
    @Inject(PLATFORM_ID) platformId: any) {
    super(eRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
    this.saveStateModalButtons = [
      {
        icon: 'save',
        tooltip: locale.translate('common.generic.save'),
        btnType: 'primary',
        btnClasses: ['customToolbarButton'],
        action: () => {
          this.addState().then(() => {
            this.toastService.showToast('Report Created', 'save', 'success');
          }).catch(() => {
            this.toastService.showToast('Failed to create report. Please try again', 'error', 'error');
          });
        },
        disabled: () => {
          return !get(this.addStateForm, 'valid', false) || !get(this.addStateForm, 'form.value.name.Name', '');
        }
      },
      {
        icon: 'clear',
        tooltip: locale.translate('common.generic.cancel'),
        btnType: 'default',
        btnClasses: ['customToolbarButton'],
        action: () => {
          this.cancelSaveState();
        }
      }
    ];

    this.authService.getCurrentProfile()
      .subscribe(userProfile => {
        this.currentUserProfileId = userProfile.Id;
      });
    this.commonModuleResourceKeys = PhoenixCommonModuleResourceKeys;

    this.exportConfig.fileName = this.exportFileName;
    this.stateStoringConfig.storageKey = this.componentName;
  }

  get pageIndex(): number {
    return this.grid.instance.pageIndex();
  }

  get pageSize(): number {
    return this.grid.instance.pageSize();
  }

  get lastPageIndex(): number {
    return Math.floor(this.grid.instance.totalCount() / this.grid.instance.pageSize());
  }

  get gridTotalCount(): number {
    return this.grid.instance.totalCount();
  }

  /** NOTE: get columns in their current order */
  get currentOrderedColumns(): string[] {
    return this.getOrderedColumnNames();
  }

  ngOnInit() {
    this.applyLocalization();
    this.loadStates();
    this.phxEditing = this.editing || {};
    this.rebindConfiguration();
    this.initHookFired = true;
    this.refreshGrid.pipe(takeUntil(this.isDestroyed$))
      .subscribe((data) => {
        this.setupDataSource(data.selectColumns, data.reportId);
        this.unlockGridUpdates();
        this.loadStates();
        this.refreshAndSaveCurrentView();
      });
  }

  onInitialized(e) {
    /** NOTE: for devexpress grid control when columnChooser.enabled is false but we still want to hide columns  
     * https://supportcenter.devexpress.com/ticket/details/t424748/datagrid-a-column-s-visible-state-is-ignored-when-columnchooser-is-set-to-false
     */
    this.dataGridTimeoutId = setTimeout(() => {
      e.component.option('stateStoring', { ignoreColumnOptionNames: [] });
    }, 100);
  }

  ngAfterContentInit(): void {
    this.templates.forEach(template => this.grid.templates.push(template));
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngAfterViewInit(): void {
    // This is an override for the class being extended, so it is legit.
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.initHookFired) {
      if (changes.exportFileName || changes.configuration?.currentValue) {
        this.rebindConfiguration();
      }
    }

    /** NOTE: for Report -> All Reports access to data grid - because of the stateId query param passed into the report-azsearch component
     * the params subscription skips loading the previous state which skips initializing the column chooser columns - so we are 
     * initializing the columns here 
     */
    if (changes.columns && this.columnChooser) {
      this.columnChooser.initColumns(changes.columns.currentValue);
    }

    this.isColumnChooserVisible = false;
  }

  ngOnDestroy() {
    this.grid.instance.hideColumnChooser();
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();

    clearTimeout(this.dataGridTimeoutId);

  }

  goNextPage() {
    const currentPageIndex = this.grid.instance.pageIndex();
    if (currentPageIndex < this.lastPageIndex) {
      this.grid.instance.pageIndex(currentPageIndex + 1);
    }
  }

  goPreviousPage() {
    const currentPageIndex = this.grid.instance.pageIndex();
    if (currentPageIndex !== 0) {
      this.grid.instance.pageIndex(currentPageIndex - 1);
    }
  }

  refreshAndSaveCurrentView() {
    const reportViewState = this.buildReportViewState(this.grid.instance.state());
    const componentName = this.componentName;
    from(this.grid.instance.refresh()).pipe(
      takeUntil(this.isDestroyed$),
      tap(() => this.saveReportViewForComponent(reportViewState, componentName))
    ).subscribe();
  }

  saveCurrentView() {
    // Grab reportViewState and componentName to prevent data corruption mid backend call.
    const reportViewState = this.buildReportViewState(this.grid.instance.state());
    const componentName = this.componentName;
    this.saveReportViewForComponent(reportViewState, componentName);
  }

  // This is an override for the class being extended, so it is legit.
  // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars
  _createInstance(element: any, options: any) {
    return this.grid.instance;
  }

  onEditorPreparing(event) {
    this.reportService.onEditorPreparing(event);
    this.editorPreparing.emit(event);
  }

  onContentReady(event) {
    this.hasFilter = this.reportService.handleFilters(this.grid.instance);

    if (Array.isArray(this.dataSource)) {
      this.currentCount = this.totalCount = this.grid.instance.getDataSource().totalCount();
    }

    if (this.configuration?.selectionMode && this.configuration.selectionMode !== PhxDataTableSelectionMode.None) {
      const newFilter = this.grid.instance.getCombinedFilter();
      if (!this.reportService.compareFilters(newFilter, this.combinedFilter)) {
        this.grid.instance.clearSelection();
      }
      this.combinedFilter = newFilter;
    }

    this.contentReady.emit(event);
  }


  onSelectionChanged(event) {
    /** NOTE: sometimes we dont want to fire this event ie. todo list  */
    if (this.fireSelectionChanged) {
      this.selectionChanged.emit(event);
    }
  }

  onRowClick(event: any) {
    this.rowClick.emit(event);
  }

  onMasterRowExpanding(event: any) {
    this.masterRowExpanding.emit(event);
  }

  onCellClick(event: any) {
    this.cellClick.emit(event);
  }

  onRowPrepared(event: any) {
    if (this.configuration.rowHighlightingConfig) {
      if (event.rowType === 'data' && event.data[this.configuration.rowHighlightingConfig.fieldName]) {
        event.rowElement.classList.add(this.configuration.rowHighlightingConfig.cssClass);
      }
    }
    this.rowPrepared.emit(event);
  }

  onCellPrepared(event: any) {
    this.cellPrepared.emit(event);
  }

  onToolbarPreparing(e) {
    this.reportService.onToolbarPreparing(e, this.configuration);

    e.toolbarOptions.items.push({
      widget: 'dxButton',
      options: {
        icon: 'columnchooser',
        onClick: () => {
          this.columnChooser.updateColumns(this.getOrderedColumnNames());
          this.isColumnChooserVisible = true;
        }
      },
      location: 'after'
    });
  }

  onContextMenuPreparing(event: any) {
    this.contextMenuPreparing.emit(event);
  }

  resetFilters() {
    this.grid?.instance?.clearFilter();
  }

  addCustomItem(data) {
    const newItem: PhxDataTableStateDetail = { Id: 0, Name: data.text, Description: '', OwnerProfileId: this.currentUserProfileId };
    if (data.text.length >= 3 && data.text.length <= 128) {
      this.stateDetails.push(newItem);
      data.customItem = newItem;
    } else {
      this.newStateDescription = '';
    }
  }

  removeState(state: PhxDataTableUserProfile) {
    this.dialogService.confirmDelete().then((button) => {
      if (button === DialogResultType.Yes) {
        const stateToRemove = state;
        this.dataTableService.deleteReportShareAdapter(stateToRemove.Id).then(() => {
          this.toastService.showToast('Report Deleted', 'delete', 'success');
          this.states.splice(this.states.findIndex((item) => item.Id === stateToRemove.Id), 1);

          this.populateStateNames();
          this.reportViewRemoved.emit();
        }).catch(() => {
          this.toastService.showToast('Failed to delete report. Please try again', 'error', 'error');
        });
      }
    }).catch(() => {
    });
  }

  saveAsState() {
    this.showPopup();
  }

  resetState() {
    this.dialogService.confirm(this.locale.translate('common.phxDataTable.confirmResetViewTitle'), this.locale.translate('common.phxDataTable.confirmResetViewMessage'))
      .then((button) => {
        if (button === DialogResultType.Yes) {
          if (this.initState) {
            this.loadGridFromState(this.initState).subscribe();
            this.resetSelectedReport.emit();
          }
        }
      });
  }

  modifyGridState(state: any) {
    this.loadPanelConfig = this.loadPanelRebuildReportConfig;
    defer(() => {
      if (state === null) {
      } else if (state.Id === 0) {
        this.rootFormGroup = null;
        this.applyInitState().subscribe();
      } else {
        this.setReportShareDetailsFormGroup(state);
        this.applyState(state);
      }
    });
  }

  saveAndShareReport($event: IReportShareDetails) {
    this.reportShareDetails = $event;
    this.addState().then(() => {
      this.toastService.showToast('Report share updated', 'group', 'success');
    }).catch(() => {
      this.toastService.showToast('Failed to update report share. Please try again', 'error', 'error');
    });
  }

  reloadGridFromSavedReportView(selectColumns, reportId, savedReportState) {
    this.reloadGridFromSavedReportViewHelper(savedReportState, reportId).subscribe(() => {
      this.setupDataSource(selectColumns, reportId);
      this.loadStates();
    });
  }

  lockGridUpdatesUntilReloadOrRefresh() {
    this.beginUpdate();
  }

  unlockGridUpdates() {
    this.endUpdate();
  }

  buildReportViewState(gridState: any) {
    return {
      state: JSON.stringify(gridState),
      selectedReportId: this.selectedReportId,
      viewDropdownState: this.getReportViewData()
    } as IReportViewState;
  }

  orderColumns(columnDataFields: string[]) {
    this.beginUpdate();
    for (let idx = 0; idx < columnDataFields.length; idx += 1) {
      this.grid.instance.columnOption(columnDataFields[idx], 'visibleIndex', idx);
    }
    this.endUpdate();
  }

  updateColumnVisibility(columns: { id: string, isVisible: boolean; }[]) {
    this.beginUpdate();
    columns.forEach(col => this.grid.instance.columnOption(col.id, 'visible', col.isVisible));
    this.endUpdate();
  }

  closeChooser() {
    this.isColumnChooserVisible = false;
  }

  updateSelections(todoListRowKeys: RowKey[]) {
    this.fireSelectionChanged = false;

    /** NOTE: we can only pass in visible rows to be selected */
    const visibleGridRowsEntityIds = this.grid.instance.getVisibleRows()?.map(m => m.key).map(m => Object.values(m)[0]) || [];
    const visibleToDoListItems = todoListRowKeys.filter(f => visibleGridRowsEntityIds.includes(Object.values(f)[0] as number));
    this.grid.instance.selectRows(visibleToDoListItems, false);

    this.fireSelectionChanged = true;
  }

  private getOrderedColumnNames() {
    const columns: { index: number, columnName: string; }[] = [];
    const gridInstance = this.grid.instance;

    /** NOTE: loop through all columns - if visible then get data field name and its current order index */
    for (let idx = 0; idx < gridInstance.columnCount(); idx++) {
      if (gridInstance.columnOption(idx, 'visible')) {
        columns.push({ index: gridInstance.columnOption(idx, 'visibleIndex'), columnName: gridInstance.columnOption(idx, 'dataField') });
      }
    }
    /** NOTE: sort columns by visible index */
    columns.sort((a, b) => {
      if (a.index > b.index) {
        return 1;
      }
      if (a.index < b.index) {
        return -1;
      }
      return 0;
    });

    /** NOTE: we only care about the column name */
    return columns.map(m => m.columnName);
  }


  private setupDataSource(selectColumns: string[], reportId: number) {
    this.selectColumns = selectColumns;
    if (this.reportId !== reportId) {
      this.reportId = reportId;
      this.grid.instance.option('dataSource', []);
      this.createAzSearchDataSource();
      this.rebindDataSource();
    }
  }

  private setReportShareDetailsFormGroup(state: PhxDataTableUserProfile) {
    this.rootFormGroup = this.formBuilder.group<IReportShareDetails>({
      StateId: [state.Id],
      OwnerProfileId: [state.UserProfileId],
      IsPublic: [state.IsPublic],
      ComponentName: [state.ComponentName],
      ShareeUserIds: [state.ShareeUserIds],
      ShareeTeamIds: [state.ShareeTeamIds]
    });
  }

  private beginUpdate() {
    this.grid.instance.beginUpdate();
  }

  private endUpdate() {
    this.grid.instance.endUpdate();
  }

  private applyLocalization() {
    /** TODO: do we need localization for the new column chooser? */
  }

  private rebindConfiguration() {
    this.exportConfig.fileName = this.exportFileName;
    this.exportConfig.enabled = this.configuration.enableExport;
    this.masterDetailConfig.enabled = this.configuration.enableMasterDetail;
    this.masterDetailConfig.template = this.configuration.masterDetailTemplateName;
    this.loadPanelDefaultConfig.text = this.configuration.loadPanelText || this.locale.translate('common.phxDataTableConfiguration.loadPanelText');
    this.loadPanelDefaultConfig.enabled = this.configuration.loadPanelEnabled;

    if (!this.configuration.noDataText) {
      this.configuration.noDataText = this.locale.translate('common.phxDataTableConfiguration.noDataText');
    }
  }

  private rebindDataSource() {
    this.totalCount = 0;
    this.currentCount = 0;
    this.phxDataSource = this.dataSource || [];
  }

  private showPopup() {
    if (this.saveModal) {
      this.addStateForm.form.updateValueAndValidity();
      this.saveModal.show();
    }
  }

  private hidePopup() {
    if (this.saveModal) {
      this.saveModal.hide();
    }
  }

  private applyState(state) {
    if (state != null) {
      this.selectedState = { Id: state.Id, Name: state.StateName, Description: state.StateDescription, OwnerProfileId: state.UserProfileId };
      if (this.addStateForm) {
        this.addStateForm.form.markAsPristine();
      }
      this.loadGridFromState(state.State).subscribe();
    }
  }

  private cancelSaveState() {
    this.hidePopup();
    this.selectedState = { Id: 0, Name: '', Description: '', OwnerProfileId: 0 };
    this.nameIsChanged = false;
    this.addStateForm.form.markAsPristine();
  }

  private addState() {
    if (this.selectedState) {

      const gridState = this.grid.instance.state();
      let stateToSave: PhxDataTableUserProfile;

      const index = this.states.findIndex(item => {
        // Does the current user already own a state by this name?
        return (item.StateName || '').trim().toLowerCase() === (this.selectedState.Name || '').trim().toLowerCase()
          && item.UserProfileId === this.currentUserProfileId;
      });
      if (index > -1) {
        stateToSave = this.states[index];
      } else {

        stateToSave = this.dataTableService.createEmptyPhxDataTableUserProfile(this.componentName);
      }
      stateToSave.StateName = this.selectedState.Name;
      stateToSave.StateDescription = this.newStateDescription;
      stateToSave.State = gridState;
      stateToSave.ComponentName = this.componentName;
      if (this.reportShareDetails) {
        stateToSave.IsPublic = this.reportShareDetails.IsPublic;
        stateToSave.ShareeUserIds = this.reportShareDetails.ShareeUserIds;
        stateToSave.ShareeTeamIds = this.reportShareDetails.ShareeTeamIds;
        this.reportShareDetails = null;
      }

      return this.doSaveState(stateToSave).then((state) => {
        this.hidePopup();
        if (state) {
          this.setReportShareDetailsFormGroup(state);
        }
        this.addStateForm.form.markAsPristine();
        this.reportViewAdded.emit(this.selectedState.Name);
      });

    }
  }

  private reloadGridFromSavedReportViewHelper(savedReportState, reportId) {
    return this.reportViewStateService.getReportSecurityViewFilterData().pipe(
      takeUntil(this.isDestroyed$),
      switchMap((apiResponse) => {
        if ((savedReportState.state || '').trim() !== '') {
          const reportViewData = this.parseReportViewData(savedReportState.viewDropdownState, reportId);
          reportViewData.teamIds = (reportViewData.teamIds || '').trim();
          reportViewData.contextualUserIds = (reportViewData.contextualUserIds || '').trim();

          if (reportViewData.teamIds === '' && reportViewData.contextualUserIds === '') {
            // 'All Company Data'
            if (!apiResponse.showAllCompanyData) {
              // Not allowed. Show 'My Data' instead.
              reportViewData.contextualUserIds = this.currentUserProfileId.toString();
            }
          } else {
            // Clean out teams and userIds no longer allowed
            reportViewData.teamIds = reportViewData.teamIds.split(',').filter(teamId => {
              return -1 !== (apiResponse.myTeams || []).findIndex(team => team.teamId === teamId);
            }).join(',');

            reportViewData.contextualUserIds = reportViewData.contextualUserIds.split(',').filter(userId => {
              return userId === this.currentUserProfileId.toString()
                || -1 !== (apiResponse.peopleReportingToMe || []).findIndex(person => person.userProfileId === userId);
            }).join(',');

            if (reportViewData.teamIds === '' && reportViewData.contextualUserIds === '') {
              // No teams and userIds left! Fall back to 'My Data'
              reportViewData.contextualUserIds = this.currentUserProfileId.toString();
            }
          }

          // Data has been sanitized. Use it.
          this.teamIds = reportViewData.teamIds;
          this.contextualUserIds = reportViewData.contextualUserIds;
          return this.loadGridFromState(JSON.parse(savedReportState.state), true).pipe(tap(() => {
            this.selectedReportIdLoad.emit(savedReportState.selectedReportId);
            this.teamIdsAndContextualUserIdsLoaded.emit({
              teamIds: reportViewData.teamIds,
              contextualUserIds: reportViewData.contextualUserIds
            });
          }));
        } else {
          return this.applyInitState();
        }
      }));
  }

  private getReportViewData(): string {
    return JSON.stringify({
      teamIds: this.teamIds,
      contextualUserIds: this.contextualUserIds
    });
  }

  private parseReportViewData(retrievedReportViewData: string, reportId: number): ILoadedReportViewData {
    if (!retrievedReportViewData) {
      let contextualUserIds = this.currentUserProfileId.toString();
      // PeopleAll and OrgAll reports default to all company data
      if ([33, 34].includes(reportId)) {
        contextualUserIds = '';
      }
      return {
        teamIds: '',
        contextualUserIds
      };
    }
    return JSON.parse(retrievedReportViewData);
  }

  private saveReportViewForComponent(reportViewState: IReportViewState, componentName: string) {
    if (!componentName) {
      return;
    }
    const reportViewStateString = JSON.stringify(reportViewState);

    if (reportViewStateString !== this.lastReportViewStateString) {
      if (!this.isSavingCurrentReportView) {
        this.lastReportViewStateString = reportViewStateString;
        this.saveCurrentReportViewHelper(reportViewState, componentName);
      } else {
        this.pendingSaveCurrentReportView = true;
      }
    }
  }

  private saveCurrentReportViewHelper(reportViewState: IReportViewState, componentName: string) {
    this.isSavingCurrentReportView = true;
    const saveReportCallComplete = () => {
      this.isSavingCurrentReportView = false;
      if (this.pendingSaveCurrentReportView === true) {
        this.pendingSaveCurrentReportView = false;
        this.saveReportViewForComponent(this.buildReportViewState(this.grid.instance.state()), this.componentName);
      }
    };
    this.reportViewStateService.saveReportViewState(componentName, reportViewState)
      .pipe(finalize(() => saveReportCallComplete())).subscribe();
  }

  private applyInitState() {
    return new Observable(subscriber => {
      if (this.initState == null) {
        this.initState = this.grid.instance.state();
      }
      this.rootFormGroup = undefined;
      this.loadGridFromState(this.initState).subscribe(() => {
        subscriber.next(true);
        subscriber.complete();
      });
    });
  }

  private createAzSearchDataSource() {
    this.dataSource = new DataSource({
      store: new ODataStore({
        url: environment.reportServiceApiEndpoint + '/report/' + this.reportId,
        key: [this.reportOdataKey],
        keyType: 'Int32',
        version: 4,
        beforeSend: (e) => {
          /** NOTE: todo list cares when data is loading */
          this.isLoadingData.emit();
          e.headers = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'azsearch-team-ids': this.teamIds,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'azsearch-contextual-user-ids': this.contextualUserIds,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'azsearch-page-size': this.configuration.pageSize?.toString()
          };
          this.searchParamsChanged.emit({
            $filter: e.params.$filter,
            $orderby: e.params.$orderby,
            $select: e.params.$select
          });
        },
        onLoading: ((options: LoadOptions) => {
          options.select = this.selectColumns;
          options.sort = !options.sort || (options.sort && Array.isArray(options.sort) && options.sort.length === 0) ? this.defaultSort : options.sort;
        })
      })
    });
  }

  private populateStateNames() {
    this.stateDetails = this.states.filter(i => {
      return i.StateName !== '' && i.UserProfileId === this.currentUserProfileId;
    }).map(i => {
      return {
        Id: i.Id,
        Name: i.StateName,
        Description: i.StateDescription,
        OwnerProfileId: i.UserProfileId
      };
    });
  }

  private loadGridFromState(state: PhxDataTableState, unlockGrid = false) {
    return new Observable(subscriber => {
      // Keep the original state at the beginning
      if (!this.initState) {
        this.initState = this.grid.instance.state();
      }

      if (state.columns?.length && this.columns?.length) {
        state.columns.forEach(c => {
          if (c?.visible && !this.columns.some(col => col.dataField === c.dataField && (col.visible || col.showInColumnChooser))) {
            c.visible = false;
          }
        });
      }
      this.columnChooser.initColumns(this.columns);
      defer(() => {
        this.grid.instance.state(state);
        this.loadPanelConfig = this.loadPanelDefaultConfig;
        if (unlockGrid) {
          this.unlockGridUpdates();
        }
        subscriber.next(true);
        subscriber.complete();
      });

      this.grid.instance.refresh();
    });
  }

  private loadStates() {
    this.dataTableService.getReportShareByComponentNameAdapter(this.componentName).then((data) => {
      this.states = data || [];

      if (!this.initState) {
        this.initState = this.grid.instance.state();
      }
      this.populateStateNames();
    }).catch(() => {
    });
  }

  private doSaveState(state: PhxDataTableUserProfile): Promise<PhxDataTableUserProfile> {
    return new Promise((resolve, reject) => {
      this.dataTableService.saveReportShareDetailAdapter(state).then((result) => {
        if (result) {
          const dbState = result;
          if (this.states == null) {
            this.states = [];
          }
          // new state
          if (state.Id === 0) {
            this.states.push(dbState);
          } else {
            const exisitingStateIndex = this.states.findIndex(item => item.Id === dbState.Id);
            if (exisitingStateIndex !== -1) {
              this.states[exisitingStateIndex].State = dbState.State;
              this.states[exisitingStateIndex].LastModifiedDatetime = dbState.LastModifiedDatetime;
            } else {
              this.states.push(dbState);
            }
          }
          resolve(dbState);
        } else {
          resolve(null);
        }
        this.populateStateNames();
      }).catch((ex) => {
        reject(ex);
      });
    });
  }
}
