import { Injectable } from '@angular/core';

import { RecipientSearchResult } from '../../models/recipient-search-result.model';
import { ReportGlobalSearchParams } from '../../../common/model/report-global-search-params.model';
import { splitArrayIntoChunk } from './utils/split-array-into-chunks.util';
import { ApiService } from '../../../common';
import { environment } from '../../../../environments/environment';
import { MailCampaignDto } from '../../../common/data-services/fbo-monolith-mail-campaign/models/mail-campaign.dto';

@Injectable()
export class RecipientsDetailsLoaderService {
  private readonly reportApiEndpoint = environment.reportServiceApiEndpoint;
  constructor(private apiService: ApiService) {}

  public async loadSavedRecipients(mailCampaign: MailCampaignDto): Promise<RecipientSearchResult[]> {
    const selectLimit = 1000;
    const assignmentsChunks = splitArrayIntoChunk(
      mailCampaign.AssignmentIds.split(',').map(id => +id),
      selectLimit
    );
    const getSearchParams = (ids: number[], index: number): ReportGlobalSearchParams => {
      return {
        filter: '(' + ids.map(id => `AssignmentId eq ${id}`).join(' or ') + ')',
        top: selectLimit,
        facets: [],
        skip: index * selectLimit
      };
    };

    try {
      const recipientsChunks: Array<{ value: RecipientSearchResult[] }> = await Promise.all(
        assignmentsChunks.map((chunk, index) =>
          this.apiService
            .fetchRequest(
              `GlobalSearch/AssignmentSearch/search`,
              {
                headers: {
                  accept: 'application/json, text/plain, */*',
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  'content-type': 'application/json'
                },
                body: JSON.stringify(getSearchParams(chunk, index)),
                method: 'POST'
              },
              this.reportApiEndpoint,
              false
            )
            .then(response => {
              if (!response.ok) {
                throw new Error('Could not fetch users');
              }
              return response.json();
            })
        )
      );

      const profileIds = new Set(mailCampaign.ToUserProfileIds.split(',').map(id => +id));
      return recipientsChunks.reduce((a, b) => {
        const relevantRecipients = b.value.filter(assignment => profileIds.has(assignment.Associations.WorkerProfileId));
        return a.concat(relevantRecipients);
      }, [] as RecipientSearchResult[]);
    } catch (e) {
      console.error(e);
    }
  }
}
