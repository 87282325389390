<div class="modal-header">
  <h4 class="modal-title pull-left">{{ dialogData.title }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="close()"
  >
    <i class="material-icons">close</i>
  </button>
</div>
<div class="modal-body">
  <p
    [innerHTML]="dialogData.message"
    class="text-break"
  ></p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-default"
    (click)="close()"
  >
    {{ dialogData.noButtonText | phxTranslate }}
  </button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="yes()"
  >
    {{ dialogData.yesButtonText | phxTranslate }}
  </button>
</div>
