<app-phx-modal [buttons]="buttonList" [fullScreen]="true" [showHeader]="false" i18n-title="@@compliance.preview.title"
    [showCloseButton]="false">

    <div class="status-container" [ngSwitch]="currentComplianceStatus">
        <ng-container *ngSwitchCase="ComplianceStatus.Compliant">
            <h3 class="ck-success">
                <span class="material-icons-outlined">
                    check_circle
                </span>
                <span i18n="@@phx.panelChecklist.compliant">Compliant</span>
            </h3>
        </ng-container>

        <ng-container *ngSwitchCase="ComplianceStatus.Warning">
            <h3 class="ck-warn">
                <span class="material-icons-outlined">
                    warning
                </span>
                <span i18n="@@phx.panelChecklist.compliantWithWarning">Compliant with Warning</span>
            </h3>
        </ng-container>

        <ng-container *ngSwitchCase="ComplianceStatus.NonCompliant">
            <h3 class="ck-critical">
                <span class="material-icons-outlined">
                    cancel
                </span>
                <span i18n="@@phx.panelChecklist.nonCompliant">Non Compliant</span>
            </h3>
        </ng-container>

    </div>

    <section class="preview-container">
        <div class="container-section documents-preview">
            <div class="iframe-container">
                <iframe title="Compliance document" id="doc-view-iframe" class="document-iframe"
                    [src]="fileUrl | sanitizeResourceUrl" *ngIf="fileUrl"></iframe>
            </div>
        </div>
        <div appResizable resizeFrom="left" class="container-section">
            <div class="compliance-data-container">
                <app-phx-document-file-compliance [complianceData]="complianceData"
                    *ngIf="complianceData"></app-phx-document-file-compliance>
            </div>
        </div>
    </section>
    <div class="compliance-message-container" *ngIf="currentComplianceStatus !== ComplianceStatus.Compliant">
        Your document has errors! <br>
        Please replace with a corrected version to avoid delays in your onboarding process.
    </div>
</app-phx-modal>