import { Component, Input } from '@angular/core';
import { rateTypeAbbreviations } from 'src/app/common/constants/timesheet.const';
import { CodeValueGroups, PhxConstants } from '../../model';

interface TimesheetRateAndAmount {
  RateTypeId: number;
  UnitAmount: number;
}

@Component({
  selector: 'app-time-sheet-overtime-breakdown',
  templateUrl: './time-sheet-overtime-breakdown.component.html',
  styleUrls: ['./time-sheet-overtime-breakdown.component.less']
})
export class TimeSheetOvertimeBreakdownComponent {
  @Input() set overtimeTimeSheetDetails(overtimeTimeSheetDetails: Array<TimesheetRateAndAmount>) {
    const otDetailsMap = new Map<number, number>();

    overtimeTimeSheetDetails?.forEach(otRateAndAmount => otDetailsMap
      .set(otRateAndAmount.RateTypeId, (otDetailsMap.get(otRateAndAmount.RateTypeId) ?? 0) + otRateAndAmount.UnitAmount));

    this.localOvertimeSheetDetails = Array.from(otDetailsMap.entries()).map(([rateTypeId, unitSum]) => ({ RateTypeId: rateTypeId, UnitAmount: unitSum }));
  };

  public readonly overtimeRateTypesToAlertInOvertimeBreakdown = new Set([PhxConstants.RateType.Overtime, PhxConstants.RateType.DoubleTime]);
  public readonly rateTypeAbbreviations = rateTypeAbbreviations;
  public readonly codeValueGroups = CodeValueGroups;
  public readonly hourUnit = PhxConstants.RateUnit.Hour;

  localOvertimeSheetDetails: Array<TimesheetRateAndAmount> = [];
}
