import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { StorageService } from 'ngx-webstorage-service';
import * as Rollbar from 'rollbar';

import { environment } from '../environments/environment';
import {LoadingSpinnerService, PhxConstants} from './common';
import { ToastService } from './common/services/toast.service';
import { MODULE_ROUTES } from './common/constants/module-routes.const';

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

const getCurrentUserPayload = (storageService: StorageService) => {
  let identity = null;
  try {
    identity = JSON.parse(storageService.get('BearerIdentity'));
    return {
      person: {
        id: 'ProfileId: ' + identity.profileId + ', TenantName: ' + identity.tenantName,
        username: identity.userName,
        email: identity.email
      }
    };
  } catch (e) {
    return {};
  }
};

export const rollbarFactory = (storageService: StorageService) => {
  if (environment.rollbar?.accessToken) {
    const config = {
      ...environment.rollbar,
      enabled: environment.rollbar.enabled === 'true'
    };
    const rollbar = new Rollbar(config);

    return {
      error: (...args: Rollbar.LogArgument[]) => {
        const payload = getCurrentUserPayload(storageService);
        rollbar.configure({ payload });
        rollbar.error(...args);
      },
      debug: () => {},
      info: () => {},
      warning: () => {},
      critical: () => {}
    };
  }
  return {
    error: (...args: any[]) => {
      // eslint-disable-next-line no-console
      console.error(...args);
    },
    debug: () => {},
    info: () => {},
    warning: () => {},
    critical: () => {}
  };
};

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  toastService: ToastService;
  loaderService: LoadingSpinnerService;

  constructor(@Inject(RollbarService) private rollbar: Rollbar, private injector: Injector) {
    this.toastService = injector.get(ToastService);
    this.loaderService = injector.get(LoadingSpinnerService);
  }

  // Literally anything can come as error, though most likely it would be an instance of HttpErrorResponse
  handleError(err?: any): void {
    const errorDetails = err?.error ?? err;
    const errorMessage = errorDetails?.message ?? err?.message;

    this.rollbar.error(errorMessage, err);

    let toastMessage = errorMessage;

    this.loaderService.hideAll();

    if (errorDetails?.detail) {
      toastMessage = errorDetails.detail;
    }

    if (errorDetails?.correlationId) {
      toastMessage = `${toastMessage} <BR> Error Code: ${errorDetails.correlationId}`;
    }

    if (errorDetails?.navigateTo) {
      const routerService = this.injector.get(Router);
      routerService.navigate([errorDetails.navigateTo]);
    }

    if (err instanceof HttpErrorResponse && this.shouldDisplayToast(err)) {
      this.toastService.logErrorUserDismissed(toastMessage);
    }
  }

  private shouldDisplayToast(err: HttpErrorResponse): boolean {
    let unwantedRuntimeErrorTypes = ['Error', 'EvalError', 'RangeError', 'ReferenceError', 'TypeError', 'URIError', 'AggregateError', 'InternalError', 'HttpErrorResponse'];

    // Custom case for Onboarding module
    const isErrorInOnboardingModule = this.injector.get(Router).url.startsWith(`/${MODULE_ROUTES.MAIN}/${MODULE_ROUTES.ONBOARDING}`);

    if (isErrorInOnboardingModule && err.status !== PhxConstants.HTTPResponseStatus.NotFound) {
      unwantedRuntimeErrorTypes = unwantedRuntimeErrorTypes.filter(t => t !== 'HttpErrorResponse');
    }

    return Boolean(!unwantedRuntimeErrorTypes.includes(err.name) && err?.message);
  }
}
